import loadable from '@loadable/component'
import React from 'react'
import { Link } from 'gatsby'
import SE0 from '../components/SEO'
import Helmet from 'react-helmet'
import config from '../../config'
import Layout from '../components/Layout'

const GalleryCard = loadable(() => import('../components/GalleryCard'))
const ContactCall = loadable(() => import('../components/ContactCall'))

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/portfolio/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

const PortfolioPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
  const nextUrl = (index + 1).toString() + '/'

  const websiteSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: config.siteTitle,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
  }

  return (
    <Layout>
        <SE0
          title="Portfolio 🌱 Art Open Sp. z o.o. | Advertising agency"
          meta_title="Portfolio 🌱 Art Open Sp. z o.o. | Advertising agency"
          meta_desc="Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and online shops, e-commerce, graphic design, VR and 3D, calendars."
          keywords="advertising agency, branding, websites, online shops, applications, ecology, e-commerce, positioning, seo, graphic design, book calendars, gadgets, prints, advertising, photography, filming, covid-19, surgical masks, masks with logo, exhibition, packaging, vr, 3D"
          slug="/portfolio/"
          cover="/img/portfolio.svg"
          siteTitleAlt={config.siteTitleAlt}
          userName={config.userName}
          siteTitle={config.siteTitle}
          siteUrl={config.siteUrl}
          siteFBAppID={config.siteFBAppID}
          userTwitter={config.userTwitter}
          pathPrefix={config.pathPrefix}
        />
        <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>

          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>

          <script type='application/ld+json'>
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://artopen.co/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "Portfolio",
            }]
          })}
          </script>

        </Helmet>
        <section className='hero is-primary is-bold' style={{minHeight:'70%',backgroundImage:'url(/img/portfolio.svg)',backgroundRepeat:'no-repeat',backgroundPosition:'top right',backgroundAttachment: `fixed`}}>
          <div className='hero-body'>
            <div className='container section'>
              <div className='columns section'>
                <div>
                  <h1 className='title' style={{fontSize:'50px',marginTop:'50px'}}>
                    Portfolio
                  </h1>
                  <h2 className='subtitle'>
                  We invite you to take a look at our realisations.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <nav className="breadcrumbs-nav section">
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>Portfolio</b></span>
        </nav>

        <div style={{padding: '0px',marginBottom:'0px',marginTop:'-20px',textAlign:'center',maxWidth:'90%',marginLeft:'5%'}}>
          <h3 className='title'> Explore the details of <span style={{color:'#23C495'}}><b>our work</b></span>,</h3>

          <h4 className='subtitle' style={{padding:'10px'}}> selected projects by category. </h4>
        </div>



        <section className='section' style={{padding: '0px', marginTop:'2%',marginLeft:'5%',marginRight:'3%'}}>
          <GalleryCard posts={group} cats={group} />
            <section className='section'>
              <div className='buttons is-centered'>
                <PaginationLink test={first} url={previousUrl} text='<' />
                <PaginationLink test={last} url={nextUrl} text='>' />
              </div>
            </section>

        </section>
        <br />
        <div className='bottomUi' style={{textAlign:'center',marginBottom:'60px'}}>
          <Link className='button-green' style={{fontSize:'15px'}} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className='button-green' style={{fontSize:'15px'}} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className='button-green' style={{fontSize:'15px'}} to='/about-us/'> Get to know us better&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        </div>

        <div style={{textAlign:'center',paddingTop:'50px'}}>
          <br />
          <h3 style={{fontSize:'18px'}}> Fill in our <Link className="inline-link" to="/quote-art-open/"><b>form</b></Link></h3>
          <br />
          <h4 style={{fontSize:'14px',marginLeft:'20%',marginRight:'20%'}}>Answer the questions and get free service pricing. In our offer you will find many services, such as <Link className="inline-link" to='/offer/graphic-design/'>graphic design</Link>, personalized <Link className="inline-link" to='/offer/book-calendars/'>book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'>company calendars</Link>, <Link className="inline-link" to='/offer/ecology/'>ecological calendars</Link> <Link className="inline-link" to='/offer/gadgets/'>advertising gadgets</Link>, <Link className="inline-link" to='/offer/printouts/'>printouts</Link>, <Link className="inline-link" to='/offer/filming/'>advertising movies</Link> and <Link className="inline-link" to='/offer/photography/'>advertising photos</Link> as well as <Link className="inline-link" to='/offer/web-pages/'>website programming, including e-commerce, applications, </Link> or <Link className="inline-link" to='/offer/vr/'>AR and VR systems.</Link></h4>
          <br />
          <br />
          <Link className='button-green' to="/quote-art-open/"> get a quote &nbsp;&nbsp;
          <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="ecology" src='/img/leaf.svg'/>
          </Link>
          <br />
          <br />
          <br />
          <br />
        </div>

        <ContactCall/>
    </Layout>
  )
}

export default PortfolioPage
